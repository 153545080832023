import React, { PureComponent } from "react";
import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Header from "../../../components/Header";
import Text from "../../../components/Text";

import CaptionedImage from "../../../components/CaptionedImage";
import SectionBody from "../../../components/SectionBody";
import CaptionedImages from "../../../components/CaptionedImages/CaptionedImage";

import ExpertTipsImage1 from "./expert_tips_1@2x.jpg";
import ExpertTipsImage2 from "./expert_tips_2@2x.jpg";
import ExpertTipsImage3 from "./expert_tips_3@2x.jpg";

const TableImage = {
  src: ExpertTipsImage1,
  alt: "Foldable black and white patio set with watercolor candle lantern."
};

const SideTableImage = {
  src: ExpertTipsImage2,
  alt: "Ceramic red outdoor stand with watercolor planter."
};
const ChairImage = {
  src: ExpertTipsImage3,
  alt: "Reclining wooden patio chair with watercolor glass of lemonade"
};

const SectionContent = () => {
  return (
    <SectionBody>
      <CaptionedImages orientation={`vertical`}>
        <CaptionedImage image={TableImage}>
          <Text>
            Save space with items like outdoor folding chairs and tables that
            can be stashed in winter for longer life.
          </Text>
        </CaptionedImage>
        <CaptionedImage image={SideTableImage}>
          <Text>
            Bring indoor style outside by adding colorful side tables and
            eclectic patterns to the space.
          </Text>
        </CaptionedImage>
        <CaptionedImage image={ChairImage}>
          <Text>
            All-weather woods add elegance and warmth to your patio design. Oil
            them regularly for increased longevity.
          </Text>
        </CaptionedImage>
      </CaptionedImages>
    </SectionBody>
  );
};

export default class ExpertTipsSection extends PureComponent {
  render() {
    return (
      <PageSection slug={`expert-tips`}>
        <SectionHeader>
          <Header style={{ color: "#73A950" }} size={`large`}>
            Comfortable, Stylish and Built to Last
          </Header>
          <Text>
            Our Expert Buyers’ tips for durable and dynamic outdoor decór
          </Text>
        </SectionHeader>
        <SectionContent />
      </PageSection>
    );
  }
}
