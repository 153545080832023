import React from "react";
import { ReactComponent as ArrowIcon } from "./Arrow.svg";

export default function PrevNextButton({ handleClick, direction, enabled }) {
  return (
    <div
      onClick={() => {
        if (enabled) {
          handleClick(direction);
        } else {
          return null;
        }
      }}
      className={`prev-next-button ${direction} ${
        enabled ? "enabled" : "disabled"
      }`}
    >
      <ArrowIcon />
    </div>
  );
}
