import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Image extends PureComponent {
  static defaultProps = {
    src: "http://placehold.it/200x200&text=FPO",
    alt: "alt text missing"
  };
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  };

  render() {
    const { alt, src, ...rest } = this.props;
    return (
      <div className="image">
        <img src={src} alt={alt} {...rest} />
      </div>
    );
  }
}
