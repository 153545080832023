import React, { PureComponent } from "react";
import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Header from "../../../components/Header";
import Text from "../../../components/Text";

import CaptionedImage from "../../../components/CaptionedImage";
import SectionBody from "../../../components/SectionBody";
import CaptionedImages from "../../../components/CaptionedImages/CaptionedImage";

import OasisImage1 from "./outdoor_oasis_1@2x.jpg";
import OasisImage2 from "./outdoor_oasis_2@2x.jpg";
import OasisImage3 from "./outdoor_oasis_3@2x.jpg";

const FoundationImage = {
  src: OasisImage1,
  alt: "Wooden patio table and chairs on a watercolor patio."
};

const ColorImage = {
  src: OasisImage2,
  alt: "Watercolor pillows with a ceramic planter and decorative hand lamp."
};
const GardenImage = {
  src: OasisImage3,
  alt:
    "Outdoor candle lantern, floral planter and bird feeder accenting a watercolor garden."
};

const SectionContent = () => {
  return (
    <SectionBody>
      <CaptionedImages orientation={`horizontal`}>
        <CaptionedImage image={FoundationImage}>
          <Header>First-Rate Foundation</Header>
          <Text>
            Whether hosting or just hanging out, it all starts with primary
            patio pieces. From side tables to shade umbrellas, we can help make
            your outside space feel like home.
          </Text>
        </CaptionedImage>
        <CaptionedImage image={ColorImage}>
          <Header>Color, Texture and Vibe</Header>
          <Text>
            Permit yourself to show personality. Bring your deck to life. Let
            function meet fashion with throw pillows, all-weather area rugs or a
            bit of candlelight.
          </Text>
        </CaptionedImage>
        <CaptionedImage image={GardenImage}>
          <Header>Great Garden Accents</Header>
          <Text>
            Let green space shine. Nature can take center stage with the
            addition of planters, bird feeders, yard ornaments and fountains to
            your lawn and garden space.
          </Text>
        </CaptionedImage>
      </CaptionedImages>
    </SectionBody>
  );
};

export default class OasisSection extends PureComponent {
  render() {
    return (
      <PageSection slug={`oasis`}>
        <SectionHeader>
          <Header style={{ color: "#00AFAA" }} size={`large`}>
            Make the Most of Your Outdoor Oasis
          </Header>
        </SectionHeader>
        <SectionContent />
      </PageSection>
    );
  }
}
