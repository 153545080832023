import React, { PureComponent } from "react";
import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Header from "../../../components/Header";
import Text from "../../../components/Text";
import Link from "../../../components/Link";

import CaptionedImage from "../../../components/CaptionedImage";
import SectionBody from "../../../components/SectionBody";
import CaptionedImages from "../../../components/CaptionedImages/CaptionedImage";

import TipsImage1 from "./tips_1_backyard_brilliance@2x.jpg";
import TipsImage2 from "./tips_2_pretty_up@2x.jpg";
import TipsImage3 from "./tips_3_DIY_party@2x.jpg";

const BackyardBrillianceImage = {
  src: TipsImage1,
  alt: "Transformed patio deck with lawn and garden items from Tuesday Morning."
};

const PrettyUpYourPatioImage = {
  src: TipsImage2,
  alt: "Colorful planter accents an outdoor space."
};
const DIYPartyDesignImage = {
  src: TipsImage3,
  alt: "Cute, spring patio set up on a wooden outdoor table. "
};

const TipsBody = ({ children, link }) => {
  return (
    <div className={`tips-body`}>
      <div className={`tips-body--main`}>{children}</div>
      <div className={`tips-body--link`}>
        <Link target="_blank" href={link || "#"}>
          Read the Tips
        </Link>
      </div>
    </div>
  );
};

const SectionContent = () => {
  return (
    <SectionBody>
      <CaptionedImages orientation={`vertical`}>
        <CaptionedImage image={BackyardBrillianceImage}>
          <TipsBody
            link={`https://placeofmytaste.com/colorful-outdoor-space-refresh-with-tuesday-morning/`}
          >
            <Header>
              <Link
                target="_blank"
                href="https://placeofmytaste.com/colorful-outdoor-space-refresh-with-tuesday-morning/"
              >
                Backyard Brilliance
              </Link>
            </Header>
            <Text>
              Let <em>Place of My Taste</em> inspire you.
            </Text>
          </TipsBody>
        </CaptionedImage>

        <CaptionedImage image={PrettyUpYourPatioImage}>
          <TipsBody
            link={`https://www.loveandrenovations.com/patio-decorating-ideas/`}
          >
            <Header>
              <Link
                target="_blank"
                href="https://www.loveandrenovations.com/patio-decorating-ideas/"
              >
                Pretty Up Your Patio
              </Link>
            </Header>
            <Text>
              <em>Love and Renovations</em> on patio décor.
            </Text>
          </TipsBody>
        </CaptionedImage>
        <CaptionedImage image={DIYPartyDesignImage}>
          <TipsBody
            link={`https://www.dwellbeautiful.com/diy-spring-tablescape-with-tuesday-morning/`}
          >
            <Header>
              <Link
                target="_blank"
                href="https://www.dwellbeautiful.com/diy-spring-tablescape-with-tuesday-morning/"
              >
                DIY Party Design
              </Link>
            </Header>
            <Text>
              <em>Dwell Beautiful</em> shares the art of DIY.
            </Text>
          </TipsBody>
        </CaptionedImage>
      </CaptionedImages>
    </SectionBody>
  );
};
export default class TipsSection extends PureComponent {
  render() {
    return (
      <PageSection slug={`tips`}>
        <SectionHeader>
          <Header style={{ color: "#73A950" }} size={`large`}>
            Ready to Transform Your Yard or Patio?
          </Header>
          <Text>These quick and easy tips can help.</Text>
        </SectionHeader>
        <SectionContent />
      </PageSection>
    );
  }
}
