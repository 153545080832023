import React from "react";
import PropTypes from "prop-types";
import Banner from "../Banner";
import Image from "../Image";

const TextBannerImage = ({ side, image }) => {
  const { src, alt } = image;
  return (
    <div className={`text-banner--image image_${side}`}>
      <div className={`text-banner--image-inner`}>
        <Image src={src} alt={alt} />
      </div>
    </div>
  );
};

function TextBanner({ children, leftImage, rightImage, slug, ...rest }) {
  return (
    <Banner type={`text`} slug={slug} {...rest}>
      <TextBannerImage side={`left`} image={leftImage} />
      <div className="text-banner--body">{children}</div>
      <TextBannerImage side={`right`} image={rightImage} />
    </Banner>
  );
}

Image.propTypes = {
  leftImage: PropTypes.object,
  rightImage: PropTypes.object
};

export default TextBanner;
