import React from "react";
import TextBanner from "../../../components/TextBanner";
import Header from "../../../components/Header";
import Text from "../../../components/Text";

import LeftImage from "./explore_in_store_1@2x.png";
import RightImage from "./explore_in_store_2@2x.png";

const InStoreBanner = () => {
  const right = {
    src: RightImage,
    alt: "Watercolor green pot and small blue planter."
  };
  const left = {
    src: LeftImage,
    alt: "Watercolor lawn gnome and yellow planter."
  };
  return (
    <TextBanner
      style={{ backgroundColor: "#D8F1F1" }}
      rightImage={right}
      leftImage={left}
      slug={`explore-in-store`}
    >
      <Header style={{ color: "#004757" }} size={`small`}>
        Explore In-Store!
      </Header>
      <Text>
        Discover weekly in-store deals and explore each location’s unique items.
        Shop early and often for our best selection at 20-60% off department
        store prices. Our low-frills store design lets us pass our savings right
        to you!
      </Text>
    </TextBanner>
  );
};

export default InStoreBanner;
