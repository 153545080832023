import React, { PureComponent } from "react";
import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Header from "../../../components/Header";
import VideoFeature from "../../../components/VideoFeature";

import SectionBody from "../../../components/SectionBody";

import VideoPoster from "./video_poster_frame@2x.jpg";
const SectionContent = () => {
  return (
    <SectionBody>
      <VideoFeature
        poster={VideoPoster}
        href={`https://www.youtube.com/watch?v=T_gDvWKQP6o`}
      />
    </SectionBody>
  );
};

export default class VideoSection extends PureComponent {
  render() {
    return (
      <PageSection slug={`video-feature`}>
        <SectionHeader>
          <Header style={{ color: "#00AFAA" }} size={`large`}>
            Turn Spaces You Have into Places You’ll Love
          </Header>
        </SectionHeader>

        <SectionContent />
      </PageSection>
    );
  }
}
