import React, { PureComponent } from "react";
import Pagination from "./Pagination";
import PrevNextButton from "./PrevNextButton";
import Image from "../Image";
import { Swipeable } from "react-swipeable";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const ClickShield = () => {
  return <div className="click-shield" />;
};
const CarouselItemInner = ({ image }) => {
  const { src, alt } = image;
  return (
    <div className={`carousel-item--inner`}>
      <Image src={src} alt={alt} />
    </div>
  );
};

const CarouselItem = ({ item, isActive }) => {
  return (
    <div className={`carousel-item ${isActive ? "active" : "inactive"}`}>
      {item.images.map((image, index) => {
        return <CarouselItemInner image={image} key={index} />;
      })}
    </div>
  );
};

const CarouselItems = ({ items, activeIndex }) => {
  return (
    <div className={`carousel-items`}>
      {/* <ClickShield /> */}
      <TransitionGroup component={null}>
        <CSSTransition
          key={items[activeIndex].slug}
          timeout={300}
          classNames={"crossfade"}
        >
          <CarouselItem item={items[activeIndex]} isActive={true} />
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default class Carousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
    this.handlePrevNextClick = this.handlePrevNextClick.bind(this);
    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.advanceCarousel = this.advanceCarousel.bind(this);
    this.retreatCarousel = this.retreatCarousel.bind(this);
  }
  handlePaginationClick(index) {
    this.setState({
      activeIndex: index
    });
  }
  handlePrevNextClick(direction) {
    switch (direction) {
      case "prev":
        this.retreatCarousel();
        break;
      case "next":
        this.advanceCarousel();
        break;
      default:
        break;
    }
  }

  advanceCarousel() {
    if (this.state.activeIndex >= this.props.items.length - 1) {
      console.log(`can't go further`);
    } else {
      this.setState(prevState => ({
        activeIndex: prevState.activeIndex + 1
      }));
    }
  }
  retreatCarousel() {
    if (this.state.activeIndex <= 0) {
      console.log(`can't go further`);
    } else {
      this.setState(prevState => ({
        activeIndex: prevState.activeIndex - 1
      }));
    }
  }

  render() {
    const { items } = this.props;
    const swipeableConfig = {
      trackMouse: true,
      preventDefaultTouchmoveEvent: true
    };
    return (
      <div className={`carousel`}>
        <Swipeable
          {...swipeableConfig}
          onSwipedLeft={this.advanceCarousel}
          onSwipedRight={this.retreatCarousel}
          className={`carousel-body`}
        >
          <PrevNextButton
            enabled={this.state.activeIndex > 0}
            handleClick={this.handlePrevNextClick}
            direction={`prev`}
          />

          <CarouselItems items={items} activeIndex={this.state.activeIndex} />
          <PrevNextButton
            enabled={this.state.activeIndex < items.length - 1}
            handleClick={this.handlePrevNextClick}
            direction={`next`}
          />
        </Swipeable>
        <Pagination
          handleClick={this.handlePaginationClick}
          items={items}
          activeIndex={this.state.activeIndex}
        />
      </div>
    );
  }
}
