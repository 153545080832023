import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class TextInput extends PureComponent {
  static defaultProps = {
    type: "text",
    slug: "input",
    value: "",
    label: "Input",
    autoComplete: "off",
    icon: "",
    handleChange: () => {}
  };
  static propTypes = {
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["text", "email", "number"]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autoComplete: PropTypes.oneOf(["on", "off"]),
    handleChange: PropTypes.func.isRequired
  };

  render() {
    const { handleChange, className, label, slug, icon, ...rest } = this.props;
    const style = {
      backgroundImage: `url(${icon})`
    };
    return (
      <div className={`input-wrapper ${className ? className : ""}`}>
        <label htmlFor={slug}>{label}</label>
        <input
          style={style}
          onChange={handleChange}
          placeholder={label}
          name={slug}
          {...rest}
        />
      </div>
    );
  }
}
