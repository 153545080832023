import React from "react";

export default function AccessibleAltText({ alt }) {
  const style = {
    display: "none"
  };
  return (
    <span
      style={style}
      className="accessible-alt-text"
      role="img"
      aria-label={alt}
    />
  );
}
