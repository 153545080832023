import React from "react";
import PropTypes from "prop-types";

function Page({ className, children, slug, ...rest }) {
  return (
    <div className={`${className ? className : ""} page page_${slug}`}>
      {children}
    </div>
  );
}

Page.propTypes = {
  slug: PropTypes.string
};
Page.defaultProps = {
  slug: "default"
};

export default Page;
