import React from "react";
import PropTypes from "prop-types";

function Banner({ children, type, slug, ...rest }) {
  return (
    <div className={`banner banner_${type} banner--${slug}`} {...rest}>
      <div className="banner--container">{children}</div>
    </div>
  );
}

Banner.propTypes = {
  slug: PropTypes.string
};
Banner.defaultProps = {
  slug: "default"
};

export default Banner;
