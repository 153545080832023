import React from "react";
import PropTypes from "prop-types";

function PageContent({ children }) {
  return <div className={`page-content`}>{children}</div>;
}

PageContent.propTypes = {
  slug: PropTypes.string
};
PageContent.defaultProps = {
  slug: "default"
};

export default PageContent;
