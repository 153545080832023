import React from "react";

export default function Button({ children, onClick }) {
  return (
    <div className="button-container">
      <button onClick={onClick} className={`button`} type={"button"}>
        {children}
      </button>
    </div>
  );
}
