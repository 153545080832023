import React, { PureComponent } from "react";
import Button from "../Button";
import TextInput from "../TextInput";
import LocationIcon from "./LocationIcon.svg";

export default class FindStoreForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    if (this.state.inputValue.length === 0) return null;
    let url = "https://www.tuesdaymorning.com/stores/search.html?q=";
    url += encodeURI(this.state.inputValue);
    window.location = url;
  }

  handleInputChange(e) {
    console.log("input change", e.target.value);
    const value = e.target.value;
    this.setState({
      inputValue: value
    });
  }

  render() {
    return (
      <div className={`form form_find-store`}>
        <TextInput
          icon={LocationIcon}
          value={this.state.inputValue}
          onChange={this.handleInputChange}
          placeholder={`700+ locations`}
        />
        <Button onClick={this.handleButtonClick}>Find Your Store</Button>
      </div>
    );
  }
}
