import React from "react";
import Link from "../Link";
import { ReactComponent as PlayButton } from "./PlayButton.svg";
export default function VideoFeature({ href, poster }) {
  const style = {
    backgroundImage: `url('${poster}')`
  };
  return (
    <Link style={style} href={href} className="video-feature" target="_blank">
      <div className="play-button">
        <PlayButton />
      </div>
    </Link>
  );
}
