import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Header extends Component {
  static defaultProps = {
    size: "medium",
    tag: "div"
  };
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]).isRequired,
    tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "div", "span"])
  };

  render() {
    const { size, tag, children, className, ...rest } = this.props;
    return React.createElement(
      tag,
      {
        className: `header header_${size} ${className ? className : ""}`,
        ...rest
      },
      children
    );
  }
}
