import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Text extends Component {
  static defaultProps = {
    size: null,
    tag: "div"
  };
  static propTypes = {
    size: PropTypes.oneOf(["small", "large"]),
    tag: PropTypes.oneOf(["p", "div", "span", "li"])
  };

  render() {
    const { size, tag, children, className, ...rest } = this.props;
    return React.createElement(
      tag,
      {
        className: `${size ? "body-text_" + size : ""} ${
          className ? className : ""
        } body-text `,
        ...rest
      },
      children
    );
  }
  s;
}
