import React, { PureComponent } from "react";

import Image from "../Image";

export default class CaptionedImage extends PureComponent {
  render() {
    const { image, children } = this.props;
    const { alt, src } = image;
    return (
      <div className={`captioned-image`}>
        <div className={`captioned-image--container`}>
          <div className="captioned-image--image">
            <div className="captioned-image--image-inner">
              <Image alt={alt} src={src} />
            </div>
          </div>
          <div className="captioned-image--caption">
            <div className="captioned-image--caption-inner">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
