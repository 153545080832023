import React from "react";

const PaginationDot = ({ isActive, ...rest }) => {
  return (
    <div
      {...rest}
      className={`pagination-dot ${isActive ? "active" : "inactive"}`}
    />
  );
};
const PaginationDots = ({ children }) => {
  return <div className={`pagination-dots`}>{children}</div>;
};
export default function Pagination({ items, handleClick, activeIndex }) {
  return (
    <div className={`pagination`}>
      <PaginationDots>
        {items.map((item, index) => {
          return (
            <PaginationDot
              key={index}
              isActive={index === activeIndex}
              onClick={() => {
                handleClick(index);
              }}
            />
          );
        })}
      </PaginationDots>
    </div>
  );
}
