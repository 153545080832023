import React from "react";
import { Switch, Route } from "react-router-dom";

import IndexPage from "./pages/IndexPage";

export default function Router() {
  return (
    <Switch>
      <Route exact path="/" component={IndexPage} />
    </Switch>
  );
}
