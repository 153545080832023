import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class CaptionedImages extends PureComponent {
  static propTypes = {
    orientation: PropTypes.oneOf(["horizontal", "vertical"])
  };
  static defaultProps = {
    orientation: "horizontal"
  };

  render() {
    const { children, orientation } = this.props;
    return <div className={`captioned-images ${orientation}`}>{children}</div>;
  }
}
