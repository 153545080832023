import React, { PureComponent } from "react";
import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Header from "../../../components/Header";
import Text from "../../../components/Text";
import CaptionedImage from "../../../components/CaptionedImage";

import SectionBody from "../../../components/SectionBody";
import CaptionedImages from "../../../components/CaptionedImages/CaptionedImage";

import TopSellingImage1 from "./top_selling_decor_1@2x.jpg";
import TopSellingImage2 from "./top_selling_decor_2@2x.jpg";
import TopSellingImage3 from "./top_selling_decor_3@2x.jpg";

const PlantersImage = {
  src: TopSellingImage1,
  alt: "Seasonal glass, ceramic and resin pots for plants."
};

const CushionsImage = {
  src: TopSellingImage2,
  alt: "A variety of top selling outdoor furniture cushions and pillows."
};

const LawnOrnamentsImage = {
  src: TopSellingImage3,
  alt: "Flamingo and crane lawn ornaments available are Tuesday Morning."
};

const SectionContent = () => {
  return (
    <SectionBody>
      <CaptionedImages orientation={`vertical`}>
        <CaptionedImage image={PlantersImage}>
          <Header style={{ color: "#004757" }} size="small">
            Planters
          </Header>
        </CaptionedImage>
        <CaptionedImage image={CushionsImage}>
          <Header style={{ color: "#004757" }} size="small">
            Cushions & Pillows
          </Header>
        </CaptionedImage>
        <CaptionedImage image={LawnOrnamentsImage}>
          <Header style={{ color: "#004757" }} size="small">
            Lawn Ornaments
          </Header>
        </CaptionedImage>
      </CaptionedImages>
    </SectionBody>
  );
};

export default class TopSellingSection extends PureComponent {
  render() {
    return (
      <PageSection slug={`top-selling`}>
        <SectionHeader>
          <Header style={{ color: "#6FA24D" }} size={`large`}>
            Top-Selling Décor
          </Header>
        </SectionHeader>
        <SectionContent />
      </PageSection>
    );
  }
}
