import React from "react";
import Banner from "../Banner";
import FindStoreForm from "../FindStoreForm";

const Form = () => {
  return (
    <div className={`find-store-banner--form`}>
      <FindStoreForm />
    </div>
  );
};
export default function FindStoreBanner({ children, slug, ...rest }) {
  return (
    <Banner type={`find-store`} slug={slug} {...rest}>
      <div className={`find-store-banner--body`}>{children}</div>
      <Form />
    </Banner>
  );
}
