import React, { PureComponent } from "react";
import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Header from "../../../components/Header";
import Text from "../../../components/Text";
import Carousel from "../../../components/Carousel";
import SectionBody from "../../../components/SectionBody";

import Moodboard1_1 from "./moodboard-1_1@2x.jpg";
import Moodboard1_2 from "./moodboard-1_2@2x.jpg";
import Moodboard2_1 from "./moodboard-2_1@2x.jpg";
import Moodboard2_2 from "./moodboard-2_2@2x.jpg";
import Moodboard3_1 from "./moodboard-3_1@2x.jpg";
import Moodboard3_2 from "./moodboard-3_2@2x.jpg";

const CarouselData = [
  {
    slug: "indoor-patio",
    images: [
      {
        src: Moodboard1_1,
        alt:
          "Enclosed patio furniture set with teal chairs, rolling side table and ornate table setup."
      },
      {
        src: Moodboard1_2,
        alt:
          "Add a vase, side table, planter, or bird statues to your outdoor oasis."
      }
    ]
  },
  {
    slug: "patio",
    images: [
      {
        src: Moodboard2_1,
        alt:
          "Matching metal patio chairs with red cushions and refreshment table."
      },
      {
        src: Moodboard2_2,
        alt:
          "Accent your deck or patio with rocking wicker patio chair, glass bird feeder, floral planter and green patio umbrella."
      }
    ]
  },
  {
    slug: "poolside",
    images: [
      {
        src: Moodboard3_1,
        alt: "Poolside wooden folding patio set with refreshments."
      },
      {
        src: Moodboard3_2,
        alt:
          "Copper beverage tub, angel lawn statue, patio umbrella, and planter for your lawn and garden."
      }
    ]
  }
];

const SectionContent = () => {
  return (
    <SectionBody>
      <Carousel items={CarouselData} />
    </SectionBody>
  );
};

export default class HeroSection extends PureComponent {
  render() {
    return (
      <PageSection slug={`hero`}>
        <SectionHeader>
          <Header style={{ color: "#00A6A2" }} size={"large"}>
            Great Prices on Items That Inspire Creativity
          </Header>
          <Text>
            Check out these seasonal looks, then head to your local store for a
            variety of options to choose from.
          </Text>
        </SectionHeader>
        <SectionContent />
      </PageSection>
    );
  }
}
