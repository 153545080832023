import React from "react";
import AccessibleAltText from "../AccessibleAltText";

export default function PageHeader() {
  const altText =
    "Outdoor chairs, tables, water fountains, planters, bird feeders and more available at Tuesday Morning.";
  return (
    <div className="page-header" title={altText}>
      <div className="page-header--inner">
        <AccessibleAltText alt={altText} />
      </div>
    </div>
  );
}
