import React, { PureComponent } from "react";
import Page from "../../components/Page";
import PageContent from "../../components/PageContent";
import PageHeader from "../../components/PageHeader";

import HeroSection from "./HeroSection";
import OasisSection from "./OasisSection";
import TopSellingSection from "./TopSellingSection";
import ExpertTipsSection from "./ExpertTipsSection";
import VideoSection from "./VideoSection";
import TipsSection from "./TipsSection";
import InStoreBanner from "./InStoreBanner";

import FindStoreBanner from "../../components/FindStoreBanner";

import Header from "../../components/Header";
import Text from "../../components/Text";

const HomeDecorBanner = () => {
  return (
    <FindStoreBanner style={{ backgroundColor: "#00AFAA" }} slug={`home-decor`}>
      <Header size={`xlarge`}>Bring home decór for the outdoors</Header>
      <Text>Visit your location to see what’s new.</Text>
    </FindStoreBanner>
  );
};

const PatioDesignBanner = () => {
  return (
    <FindStoreBanner
      style={{ backgroundColor: "#00718A" }}
      slug={"patio-design"}
    >
      <Header size={`xlarge`}>Patio design on your mind?</Header>
      <Text>Find your location now.</Text>
    </FindStoreBanner>
  );
};

export default class IndexPage extends PureComponent {
  render() {
    return (
      <Page slug={`index`}>
        <PageContent>
          <PageHeader />
          <HeroSection />
          <HomeDecorBanner />
          <TopSellingSection />
          <OasisSection />
          <InStoreBanner />
          <ExpertTipsSection />
          <VideoSection />
          <PatioDesignBanner />
          <TipsSection />
        </PageContent>
      </Page>
    );
  }
}
